.bg{
    width: 100%;
    height: 70vh;
    background-image: linear-gradient(rgba(0,0,0,0.55),rgba(0,0,0,0.55)), url(../../Images/background.jpg);
    background-size: cover;
    background-position:center;
    overflow: hidden;
}
/*main text*/
.main-text{
    color: white;
    font-size: 6rem;
    font-family:Arial, Helvetica, sans-serif;
    text-align: center;
    top: 30%;
    position: relative;
    font-weight: bold;
}
/*end of main text*/
/*grid container*/
.grid-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    position: relative;
    top: -20vh;
    grid-gap: 20px;
    background-color: rgb(226, 226, 226);
    margin-left: 100px;
    margin-right: 100px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 5px;
}
/*end of grid container*/
/*zoom*/
.grid-item{
    transition: transform .2s;
    width: 90%;
    height: auto;

    
}
.grid-item:hover{
    transform: scale(1.05);
}
.cardimg:hover{
    filter: brightness(40%);
}
/*end of zoom*/
.cardimg{
    position: relative;
    margin: 0px;
    
}

.cardtext{
    text-align: center;
    text-decoration: none;
    color: rgb(0, 0, 0);
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #60b3e5;
    position: absolute;
    top:inherit;
    padding-right: 10px;
    padding-left: 5px;
    border-radius: 0 0px 10px 0px;
    font-weight: bold;
}
a:link{
    text-decoration: none;
}
/*text*/
.sronazev{
    text-align: center;
    position: relative;
    top: -15vh;
    font-weight: bold;
    

}
.containertext{
    position: relative;
    top: -12vh;
    width: 100%;
    padding-left: 400px;
    padding-right: 400px;
    font-size: 30px;
    
}
.lineblue{
    height: 3px;
    width: 100%;
    background-color: #60b3e5;
}
.firsttext{
    margin-bottom: 20px;
}
.secondtext{
    margin-top: 20px;
}
.containertext2{
    position: relative;
    width: 100%;
    padding-left: 400px;
    padding-right: 400px;
    font-size: 30px;
    background-color: rgb(226, 226, 226);
    padding-top: 50px;
    padding-bottom: 50px;
    
    
}
.vysledku{
    text-align: center;
    position: relative;
    font-weight: bold;
    margin-bottom: 20px;
    
}
.vysledkupad{
    text-align: center;
}

.vysledku1{
    margin-bottom: 10px;
}
.vysledku2{
    margin-bottom: 10px;
}
.dot{
    height: 7px;
    width: 7px;
    background-color: #60b3e5;
    display: inline-block; 
    margin-bottom: 4px;
}
.containertext3{
    margin-top: 120px;
    position: relative;
    width: 100%;
    padding-left: 400px;
    padding-right: 400px;
    font-size: 30px;
    padding-bottom: 50px;
}
/*end of text*/
.grid3-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    position: relative;
    grid-gap: 20px;
    background-color: white;
    margin-left: 15%;
    margin-right: 15%;
    border-radius: 5px;
    padding-right: 20px;
}
.grid3-item{
    position: relative;
}
.grid3-img{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0px;
    border-radius: 5px;
}

/*small version*/

@media (max-width: 1720px){
    .grid-container{
        grid-template-columns: 1fr 1fr;
        max-width: 70%;
        margin-left:  15%;
        top: -30vh;  
    }
    .containertext{
        padding-left: 200px;
        padding-right: 200px;
    }
    .containertext2{
        padding-left: 200px;
        padding-right: 200px;
    }
    .containertext3{
        padding-left: 200px;
        padding-right: 200px;
    }
    .main-text{
        font-size: 80px;
    }

  }  
@media (max-width: 1300px){
    .main-text{
        font-size: 60px;
    }
    .containertext{
        padding-left: 100px;
        padding-right: 100px;
    }
    .containertext2{
        padding-left: 100px;
        padding-right: 100px;
    }
    .containertext3{
        padding-left: 100px;
        padding-right: 100px;
    }
    .grid3-container{
        margin-left: 5%;
        margin-right: 5%;
    }
    .grid-item:hover{
        transform: scale(1);
    }
  }
/*end of small version*/

/*mobile version*/
@media (max-width: 800px){
    .grid-container{
        grid-template-columns: 1fr;
        padding-top: 45.5vh;
        max-width: 100%;
        margin: 0px;
    }
    .main-text{
        display:none
    }
    .bg{
        height: 0vh;
    }
    .containertext{
        padding-left: 20px;
        padding-right: 20px;
        font-size: 23px;
    }
    .containertext2{
        padding-left: 20px;
        padding-right: 20px;
        font-size: 23px;
    }
    .containertext3{
        padding-left: 20px;
        padding-right: 20px;
        font-size: 23px;
    }
    .cardimg:hover{
        filter: brightness(100%);
    }
    .grid3-container{
        margin: 0px;
        max-width: 100%;
        grid-template-columns: 1fr;
        padding: 0px;
    }
    .grid3-item-2{
        display:none;
    }
    .grid3-item-1{
        position: relative;
        margin: 0px;
        width: 100%;
    }
    .grid3-img{
        border-radius: 0px;
    }

  }

/*end of mobile version*/
