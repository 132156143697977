::-moz-selection { /* Code for Firefox */
  color: rgb(255, 255, 255);
  background: #60b3e5;
}

::selection {
  color: rgb(255, 255, 255);
  background: #60b3e5;
}
@media (max-width: 1400px){
  .slider{
    display: none;
  }
}