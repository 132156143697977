.header-container{
    width: 850px;
    height: 40px;
    position: absolute;
    top: 0%;
    padding-top: 7px;
    background-color: white;
    border-bottom: #60b3e5 solid;
    left: 30%;
}
.header-none{
    display: none;
}
.header-text{
    color: rgb(85, 85, 85);
    position: relative;
    font-size: 16px;
    margin-right: 15px;
    text-decoration: none;
    font-weight: bold;
}
.header-text:hover{
    color: #60b3e5;
}
.margin-text{
    margin-left: 15px;
}

@media (max-width: 1199px){
    .header-container{
        display:none;
    }
    .header-none{
        display: none;
    }
  }