.kontakt-page{
    background-color: rgb(226, 226, 226);
    padding-bottom: 40px;
    padding-top: 9.5%;
    min-height: 100vh
}
.grid5-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    position: relative;
    grid-gap: 20px;
    background-color: rgb(255, 255, 255);
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 5px;
}
.grid5-item1{
    padding: 30px;
}
.icon-kontakt{
    font-size: 70px;
    color: #60b3e5;
}
.icon-kontakt2{
    font-size: 40px;
}
.text-kontakt{
    padding-left: 70px;
    font-size: 22px;
    margin-bottom: 16px;
    color: black;
    text-decoration: none;
}
.text-kontakt:hover{
    color: #60b3e5;
}
.kontakt1{
    margin-bottom: 50px;
}
.kontakt2{
    margin-bottom: 50px;
}
.grid5-item2{
    width: 100%;
}
.map-kontakt{
    padding: 0px;
    width: 100%;
    height: 100%;
    border-radius: 0px 5px 5px 0px;
}
#kontakt-jmeno{
    padding-left: 80px;
    margin-bottom: 25px;
}
#kontakt-jmeno:hover{
    color: black;
}
/*grid6 container*/
.grid6-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    position: relative;
    background-color: rgb(255, 255, 255);
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 5px;
    margin-top: 30px;
}
.grid6-item1{
    padding: 30px;
}
.grid6-item2{
    padding: 30px;
}
.grid6-nadpisy{
    text-align: center;
    margin-bottom: 20px;
}
.icon-kontakt3{
    font-size: 40px;
    color: #60b3e5;
}
.grid6-location{
    text-align: center;
    margin-bottom: 16px;
}
.grid6-ico{
    text-align: center;
    margin-bottom: 16px;
}
.grid6-dic{
    text-align: center;
}
#grid6-location{
    margin-bottom: 16px;
}
/*small version*/

@media (max-width: 1720px){
    .grid5-container{
        grid-template-columns: 1fr 1fr;
    }
    .kontakt1{
        font-size: 20px;
    }
    .kontakt-page{
        padding-top: 12%;
    }
    .grid6-item1{
        padding: 10px;
    }
    .grid6-item2{
        padding: 10px;
    }

  }  
@media (max-width: 1315px){
    .kontakt1{
        font-size: 15.5px;
    }
  }
/*end of small version*/

/*mobile version*/
@media (max-width: 1170px){
    .grid5-container{
        grid-template-columns: 1fr;
    }
    .grid6-container{
        grid-template-columns: 1fr;
        background-color: rgb(226,226,226);
    }
    .grid6-container{
        margin-left: 0px;
        margin-right: 0px;
    }

    .map-kontakt{
        border-radius: 0;
    }
    .grid5-item2{
        height: 600px;
    }
    .kontakt1{
        font-size: 25px;
    }

  }
  @media (max-width: 800px){
    .grid5-container{
        grid-template-columns: 1fr;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 10%;
        background-color: rgb(226, 226, 226);
        padding-bottom: 0px;
    }
    .text-kontakt{
        font-size: 20px;
    }
    .grid5-item1{
        padding: 20px;
    }
    .grid5-item2{
        padding: 0px;
        margin: 0px;
    }
    .kontakt-page{
        padding-bottom: 0px;
    }

  }
  @media (max-width: 450px){
    .grid5-item1{
        padding: 15px;
        margin-top: 12%;
    }
  }
  @media (max-width: 386px){
    .kontakt3{
       font-size: 20px;
    }
    .kontakt2{
        font-size: 20px;
    }
    
  }
  @media (max-width: 334px){
    .grid5-item1{
        padding: 0px;
        margin-top: 15%;
    }
  }
  @media (max-width: 360px){
    .text-kontakt{
        font-size: 15px;
    }
    .kontakt3{
        font-size: 16px;
     }
     .kontakt2{
         font-size: 16px;
     }

  }
/*end of mobile version*/