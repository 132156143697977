/*navbar style*/
.navbar{
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #fffffff7;     /*#fffffff7*/
  width: 100%;
  color: white;
}
.nav__grey{
  background-color: rgb(255, 255, 255);
  height: 120px;
  padding-top: 40px;
}

/*end of navbar style*/

/*logo*/
.navbar-brand{
  padding-top: 0px;
  padding-bottom: 0px;
}
/*end of logo*/
.nav-link{
  color: black;
  font-size: 20px;
  height: 80px;
  padding-top: 25px;
  padding-right: 10px;
  text-align: center;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
  position: relative;
  text-decoration: none;
}


img{
  margin: 5px;
}
.img70{
  margin-bottom: 0px;
}
.img110{
  margin-bottom: 40px;
}

@media (min-width: 1200px){
  .navbar-expand-xl .navbar-nav .nav-link {
    margin-right: 30px;
    margin-left: 30px;
  }
  .navbar{
    padding-left: 12%;
    padding-right: 10%;
  }
  .nav-link:after{
    content: "";
    height: 3px;
    width: 0;
    background-color: #60b3e5;
    position: absolute;
    left: 0;
    bottom: 15px;
    transition: 0.5s;
  }
  .nav-link:hover:after{
    width: 100%;
  }
  
}
@media (max-width: 1199px){
  .link-color{
    background-color: #ffffff;
  }
  .link-color2{
    background-color: #ffffff;
  }
  .navbar-brand{
    margin-left: 20px;
  }
  .nav__grey{
    background-color: rgb(255, 255, 255);
    height: 80px;
    padding-top: 0px;
    
  }

}

/*Start of Toggler*/
.navbar-toggler{
  width: 30px;
  height: 30px;
  position: relative;
  transition: .5s ease-in-out;
}
.navbar-toggler {
  margin-right: 20px;
}
.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus{
  outline: none;
  box-shadow: none;
  border: none;
}
.navbar-toggler span{
  margin: 0;
  padding: 0;
}
.toggler-icon{
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: rgb(0, 0, 0);
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}
.middle-bar{
  margin-top: 0px;
}
/*when navigation is clicked*/
.navbar-toggler .top-bar{
  margin-top: 0px;
  transform: rotate(135deg);

}
.navbar-toggler .middle-bar{
  opacity: 0;
  filter: alpha(opacity=0);
  
}
.navbar-toggler .bottom-bar{
  margin-top: 0px;
  transform: rotate(-135deg);
}
/*navbar is collapsed*/
.navbar-toggler.collapsed .top-bar{
  margin-top: -12px;
  transform: rotate(0deg);
}
.navbar-toggler.collapsed .middle-bar{
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-toggler.collapsed .bottom-bar{
  margin-top: 12px;
  transform: rotate(0deg);
}

/*End of Toggler*/

/*dropdown*/
.dropdown{
  position: absolute;
  width: 300px;
  transform: translateX(-25%);
  background-color: #fffffff7;
  text-align: center;
  overflow: hidden;
  transition-timing-function: ease-in;
  transition: 0.7s;
  border-radius: 0px 0px 5px 5px;
}
.dropdown-bg{
  background-color: white;
}
.dropdown-item{
  text-align: center;
  align-items: center;
  display: flex;
    color: rgb(0, 0, 0);
  font-size: 18px;
  text-align: center;
  position: relative;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.dropdown-item:hover{
  color: #60b3e5;
}
#drop-item{
  margin-left: 5px;
}
#drop-item::after{
  content: none;
}
#drop::after{
  content: none;
}
@media (max-width: 1199px){
  .dropdown{
    transform: translateX(0px);
    justify-content: center;
    width: 100%;
    position: relative;
    background-color: rgb(226, 226, 226);
  }
  .dropdown-item{
    justify-content: center;
  }
  .dropdown-item:hover{
    color: black;
  }
  #drop{
    background-color: white;
  
  }
  
}
.dropdown-icon{
  position: absolute;
}

/*end of dropdown*/